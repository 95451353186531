@font-face {
  font-family: "Fattern";
  src: url("./fonts/Fattern.otf");
  src: url("./fonts/Fattern.otf") format("opentype");

  font-weight: normal;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
