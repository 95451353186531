@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

/* *{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

$bg-color: #F6F8FC;
$primary-text-color: #212943;
$secondary-text-color: #9EA6AE;
$details: #DEE4F1; */

/* body {
  font-family: 'Poppins', sans-serif;
  font-size: 10px;
  background: $bg-color;
} */

.heading {
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.container {
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

form {
  display: flex;
  width: 60%;
  background-color: #fff;
  align-items: center;
  margin: 0 auto;
  border-radius: 1rem;
}

form svg {
  height: 1rem;
  fill: red;
  position: fixed;
  width: 4rem;
}

.searchbar {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: black;
  border: none;
  padding: 1rem;
  padding-left: 4rem;
  width: 100%;
  box-shadow: 0px 5px 13px 0px red;
  border-radius: 0.8rem;
}

.searchbar:focus {
  outline: none;
  box-shadow: 0 0 0 1.5px red, 0px 5px 13px 0px red;
}

.faq {
  margin: 3rem 0;
}
.question-wrapper {
  width: 60%;
  border-bottom: 1px solid red;
  margin: 0 auto;
  padding: 1.5em;
  transition: 1s;
}

.question {
  display: flex;
  font-size: 1rem;
  color: red;
  display: flex;
  justify-content: space-between;
}

.question svg {
  width: 1rem;
  height: 1.5rem;
  fill: red;
}

.question svg.active {
  transform: rotate(180deg);
}

.question svg:hover {
  opacity: 0.8;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

button:focus {
  outline: none;
}

.answer {
  display: none;
}

.answer.active {
  display: block;
  text-align: left;
  padding-top: 2rem;
  font-weight: light;
  font-size: 1rem;
  line-height: 1.5;
  color: red;
  height: 0%;
  animation: slidein 0.4s;
  animation-fill-mode: forwards;
}

@keyframes slidein {
  from {
    opacity: 0.3;
    transform: translateY(20%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
