.vertical-timeline-element-content {
  box-shadow: none;
}

@media only screen and (min-width: 1170px) {
  .vertical-timeline--two-columns .vertical-timeline-element-content {
    margin-left: 0;
    padding: 1rem 3.5em 1.5em 3.5em;
    width: 44%;
  }
  .vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2 2s;
    animation: cd-bounce-2 2s;
    animation-duration: 2s;
  }

  .vertical-timeline--two-columns.vertical-timeline--animate
    .vertical-timeline-element:nth-child(even):not(
      .vertical-timeline-element--left
    )
    .vertical-timeline-element-content.bounce-in {
    animation: cd-bounce-2-inverse 2s;
    animation-duration: 2s;
  }

  .vertical-timeline-element-date {
    font-size: 1.4rem !important;
    padding: 0.4rem !important;
    color: white !important;
    opacity: 1 !important;
  }
}
@media only screen and (max-width: 1170px) {
  .vertical-timeline-element-icon {
    width: 20px !important;
    height: 20px !important;
    margin: 15px 0px 0px 10px !important;
  }

  .vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
    animation: none 0s;
  }

  .vertical-timeline--two-columns.vertical-timeline--animate
    .vertical-timeline-element:nth-child(even):not(
      .vertical-timeline-element--left
    )
    .vertical-timeline-element-content.bounce-in {
    animation: none 0s;
    animation-duration: 0s;
  }
}
