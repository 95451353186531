.animation2 {
  transition: all 0.4s ease 0s;
}

.item2:hover .survivor-girl-two {
  transform: translate3d(10%, 0, 0) scale(1.3);
}

.item2:hover .number2 {
  transform: translate3d(-20%, 0, 0);
}

.item2:hover .survivor-title2 {
  transform: translate3d(10%, 0, 0);
}

.item2:hover .survivor-arrow2 {
  opacity: 1;
}
