.answer-active {
  animation: slidein 0.5s;
  animation-fill-mode: forwards;
}

@keyframes slidein {
  from {
    opacity: 0.3;
    transform: translateY(-20%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes slideInLeft {
  0% {
    opacity: 0.3;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.questionGlass {
  animation: slideInLeft 1s;
  animation-fill-mode: forwards;
}
